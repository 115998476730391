.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

/* .App-link {
  color: #61dafb;
} */
.dark-mode .icon {
  fill: #ffffff; /* Change this to your preferred color for icons in dark mode */
}
body.dark-mode {
  background-color: #010118; /* Change this to your preferred dark background color */
  color: #ffffff; /* Change this to your preferred text color in dark mode */
}
.dark-mode h2 {
  color: #ffffff; /* Change this to your preferred text color in dark mode */
}
.dark-mode .google-visualization-table-th, 
.dark-mode .google-visualization-table-td {
    color: #fff !important;
}

.dark-mode label {
  color: #ffffff; /* Change this to your preferred text color in dark mode */
}.dark-mode data {
  color: #ffffff; /* Change this to your preferred text color in dark mode */
}
.dark-mode input {
  background-color: #333; /* Dark background color for input boxes */
  color: #ffffff; /* Text color for input boxes in dark mode */
}
.dark-mode select {
  background-color: #333; /* Dark background color for input boxes */
  color: #ffffff; /* Text color for input boxes in dark mode */
}
.dark-mode ::placeholder {
  color: #ffffff; /* Text color for input boxes in dark mode */
}
.dark-mode .containerr {
  background-color: #333; /* Dark background color for input boxes */
  color: #ffffff; /* Text color for input boxes in dark mode */
  box-shadow: gray 3px 3px 3px;
}
.dark-mode .paginateNumber {
  background-color: #fff; /* Dark background color for input boxes */
  color: blue; /* Text color for input boxes in dark mode */
  box-shadow: gray 3px 3px 3px;
}
.dark-mode.chart-container > div > div {
  background-color: #333; /* Dark background color for input boxes */
  color: #ffffff; /* Text color for input boxes in dark mode */
  box-shadow: gray 3px 3px 3px;
}
.dark-mode-input {
  background-color: #333 !important;
  color: white !important;
}

.dark-mode-button {
  background-color: #333 !important;
  border-color: #444 !important;
  color: white !important;
}

.dark-mode-dropdown {
  background-color: #333 !important;
  color: white !important;
}


.dark-mode .containerrr {
  background-color: #333; /* Dark background color for input boxes */
  color: #ffffff; /* Text color for input boxes in dark mode */
}
.dark-mode .containerrrr {
  background-color: #616060; /* Dark background color for input boxes */
  color: #ffffff; /* Text color for input boxes in dark mode */
}
.dark-mode textarea {
  background-color: #333; /* Dark background color for input boxes */
  color: #ffffff; /* Text color for input boxes in dark mode */
}

/* Add additional styles for dark mode as needed */
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
